import React from 'react'
import PropTypes from 'prop-types'
import { Box, Layer, Text } from 'grommet'
import MDSpinner from 'react-md-spinner'
import { Loading as LoadingIcon } from './Icons'

const colorProps = {
  color1: '#000',
  color2: '#414141',
  color3: '#bababa',
  color4: '#707070',
}

const Loading = ({ overlay, text }) => (
  <>
    {overlay ? (
      <Layer full>
        <Box
          fill={true}
          justify="center"
          alignContent="center"
          align="center"
          gap="medium"
        >
          {text && <Text>{text}</Text>}
          <MDSpinner {...colorProps} />
        </Box>
      </Layer>
    ) : (
      <div>
        <MDSpinner {...colorProps} />
      </div>
    )}
  </>
)

Loading.propTypes = {
  overlay: PropTypes.bool,
}

export default Loading
