import React from 'react'
import { Box, ResponsiveContext } from 'grommet'
import { includes } from 'lodash/fp'

const ResponsiveHidden = ({ children, hideAt = 'small', ...rest }) => {
  const sizes = Array.isArray(hideAt) ? hideAt : [hideAt]
  return (
    <ResponsiveContext.Consumer>
      {size => (includes(size, sizes) ? null : <>{children}</>)}
    </ResponsiveContext.Consumer>
  )
}

export default ResponsiveHidden
