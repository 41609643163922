import React from 'react'
import { Heading } from 'grommet'

const AppHeading = ({ children, level = 1, italic, lower, ...rest }) => (
  <Heading
    level={level}
    style={{
      ...rest.style,
      textTransform: lower ? 'none' : 'uppercase',
      fontStyle: italic && 'italic',
    }}
    {...rest}
  >
    {children}
  </Heading>
)

export default AppHeading
