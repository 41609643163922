import React, { Component } from 'react'
import styled from 'styled-components'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { isEqual } from 'lodash/fp'

import uploadToS3 from '../lib/uploadToS3'

import { Meter, Box, Text, Stack } from 'grommet'
import { Upload, StatusGood, Close } from 'grommet-icons'

import UploadedS3File from './UploadedS3File'
import Loading from './Loading'

import Heading from './Heading'
import Button from './Button'
import getSignedUrl from '../lib/getSignedUrl'
const apiUrl = process.env.REACT_APP_API_URL

const DropPrompt = styled(Box)`
  width: 100%;
  min-height: ${p =>
    p.minHeight
      ? p.minHeight
      : isEqual(p.product, 'letters')
      ? '268px'
      : '200px'};
  max-width: ${p =>
    p.maxWidth ? p.maxWidth : isEqual(p.product, 'letters') ? '207px' : 'none'};
  padding: 0.6em;
  line-height: 1.4em;
  text-align: center;
  cursor: pointer;
  border: ${p =>
    p.complete
      ? '2px solid #e6e6e6'
      : p.active
      ? '2px dashed #f2424d'
      : p.isUploading
      ? '2px solid #000'
      : '2px dashed #05293e'};
  transition: all 0.3s;
  ${p => (p.padTop ? 'padding-top: 40px;' : '')}
`
const Container = styled(Box)`
  min-height: 150px;
  margin: 10px 0;
  opacity: ${p => (p.disabled ? '0.4' : '1')};
  transition: all 0.3s;
  position: relative;
`

class FormInputS3 extends Component {
  constructor(props) {
    super(props)
    this.state = { uploading: false, error: null, progress: 100, keyHash: null }
  }
  onUploadStart = () => {
    console.log('START UPLAOD')
  }
  onSignedUrl = e => {
    console.log('SIGNED URL', e)
  }
  onUploadProgress = progress => {
    this.setState({ progress })
  }
  onUploadError = e => {
    console.log('UPLOAD ERROR', e)
  }
  onUploadFinish = (signedUrl, file, url, fileName) => {
    const { onUploadFinished, form } = this.props
    const {
      field: { name },
      form: { setFieldValue },
    } = this.props
    this.setState({ uploading: false, error: null })
    setFieldValue(name, url)
    setFieldValue(`${name}-filename`, file.name)
    if (onUploadFinished) {
      onUploadFinished(url, fileName, file.name)
    }
  }
  onDrop = async (acceptedFiles, b) => {
    if (acceptedFiles.length) {
      this.setState({ uploading: true, error: null })
      const file = acceptedFiles[0]
      const {
        signedUrl,
        fileName,
        bucketName,
        s3Key,
        keyHash,
      } = await getSignedUrl(file)
      this.setState({ keyHash })
      uploadToS3(
        file,
        signedUrl,
        {
          onProgress: this.onUploadProgress,
          onFinishS3Put: this.onUploadFinish,
          onError: this.onUploadError,
        },
        s3Key,
        bucketName,
        keyHash
      )
    } else {
      this.setState({ error: 'Please select a file to upload.' })
    }
  }

  removeFile = () => {
    const {
      field: { name },
      form: { setFieldValue },
    } = this.props
    setFieldValue(name, null)
  }

  render() {
    const {
      field,
      form,
      filename,
      label,
      accept,
      prompt = 'Drag and drop file',
      disabled,
      onPreview,
      padTop,
      product,
      minHeight,
      maxWidth,
    } = this.props
    const { value } = field
    const {
      state: { progress },
    } = this

    return (
      <Container disabled={disabled}>
        <div>
          <Dropzone
            onDrop={this.onDrop}
            accept={accept}
            disabled={disabled || value ? true : false}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <DropPrompt
                  {...getRootProps()}
                  justify={value ? 'between' : 'center'}
                  align="center"
                  alignItems="center"
                  active={isDragActive}
                  background={value ? 'dark-1' : 'light-3'}
                  complete={value ? true : false}
                  elevation={isDragActive ? 'xlarge' : 'none'}
                  isUploading={progress < 100}
                  padTop={padTop}
                  product={product}
                  minHeight={minHeight}
                  maxWidth={maxWidth}
                >
                  {value && (
                    <Box
                      justify="between"
                      alignContent="between"
                      align="stretch"
                      alignSelf="stretch"
                      direction="row"
                    >
                      <Button
                        hoverIndicator
                        pad="small"
                        color="light"
                        size="small"
                        onClick={() => this.removeFile(filename)}
                        icon={<Close size="small" />}
                        label="Remove"
                        style={{ opacity: 0.6 }}
                        plain
                      />
                      <StatusGood color="#fff" />
                    </Box>
                  )}
                  <input {...getInputProps()} />
                  <Heading level={4} margin={{ vertical: 'small' }}>
                    {label}
                  </Heading>
                  <>
                    {value && (
                      <UploadedS3File
                        filename={filename}
                        url={value}
                        onPreview={onPreview}
                        label={label}
                      />
                    )}

                    {progress < 100 && (
                      <Meter
                        values={[
                          {
                            value: progress,
                            label: 'Uploading...',
                            color: 'brand',
                          },
                        ]}
                      />
                    )}

                    {this.state.uploading && progress === 100 && (
                      <Box direction="row" align="center">
                        <Loading />
                      </Box>
                    )}

                    {!this.state.uploading && progress === 100 && !value && (
                      <>
                        <Text size="small">{prompt}</Text>
                        <Box margin="small" flex="shrink">
                          <Button label="Choose file" size="small" />
                        </Box>
                      </>
                    )}
                  </>
                </DropPrompt>
              )
            }}
          </Dropzone>
        </div>
      </Container>
    )
  }
}

export default FormInputS3
