import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'grommet'
import { isNil } from 'lodash/fp'

const FieldSet = ({ children, border, horizontal, pad }) => (
  <Box
    justify={horizontal ? 'between' : 'center'}
    border={isNil(border) ? 'bottom' : border}
    direction={horizontal ? 'row' : 'column'}
    align={horizontal ? 'end' : 'stretch'}
    pad={pad ? pad : horizontal ? { vertical: 'small' } : 'none'}
  >
    {horizontal ? children : <Box pad={{ vertical: 'small' }}>{children}</Box>}
  </Box>
)

export default FieldSet
