import React from 'react'
import { Box } from 'grommet'

const ColumnStack = ({ children, ...rest }) => (
  <Box direction="row-responsive" {...rest}>
    {children}
  </Box>
)

export default ColumnStack
