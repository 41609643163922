import React from 'react'
import { Blank } from 'grommet-icons'

export const ChevronRight = props => (
  <Blank viewBox="0 0 24 24" a11yTitle="Add" {...props}>
    <path
      d="M18.878 11.995L9.835 24.013l-4.442-.017 8.777-11.667c.15-.198.15-.472 0-.67L5.407.047 9.86.043l9.018 11.952z"
      fillRule="nonzero"
    />
  </Blank>
)
export const ChevronLeft = props => (
  <Blank viewBox="0 0 24 24" a11yTitle="Add" {...props}>
    <path
      d="M5.244 12.09L14.287.07l4.443.017-8.778 11.667c-.15.198-.15.472 0 .67l8.763 11.611-4.453.005-9.018-11.952z"
      fillRule="nonzero"
    />
  </Blank>
)
export const ChevronUp = props => (
  <Blank viewBox="0 0 24 24" a11yTitle="Add" {...props}>
    <path
      d="M11.954 5.313l12.018 9.043-.018 4.443-11.666-8.778c-.198-.15-.472-.15-.671 0L.007 18.784l-.005-4.453 11.952-9.018z"
      fillRule="nonzero"
    />
  </Blank>
)
export const ChevronDown = props => (
  <Blank viewBox="0 0 24 24" a11yTitle="Add" {...props}>
    <path
      d="M12.02 18.799L.002 9.756l.017-4.443 11.666 8.778c.2.15.473.15.672 0l11.61-8.763.005 4.453-11.952 9.018z"
      fillRule="nonzero"
    />
  </Blank>
)
