import { Link } from 'gatsby'
import { Box, Button } from 'grommet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React from 'react'
import ConstrainedContainer from './ConstrainedContainer'
import { Menu } from 'grommet-icons'
import MainMenu from './MainMenu'
import ProductNav from './ProductNav'
import Logo from './Logo'

class Header extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }
  toggleMenu(state) {
    this.setState({ menuOpen: state || !this.state.menuOpen })
  }
  render() {
    const { siteTitle, productNav } = this.props
    return (
      <Box direction="row" pad={{ vertical: 'small' }}>
        <Box flex={!productNav} width="40px">
          <Link to="/">
            <Logo size="40px" />
          </Link>
        </Box>
        {productNav && (
          <Box flex>
            <ProductNav product={productNav} />
          </Box>
        )}
        <Box width="22px" height="22px">
          <Button onClick={() => this.toggleMenu()}>
            <Menu />
          </Button>
        </Box>
        <MainMenu
          onClose={this.toggleMenu}
          siteTitle={siteTitle}
          isOpen={this.state.menuOpen}
        />
      </Box>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default connect(
  null,
  {}
)(Header)
