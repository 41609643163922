import React from 'react'
import { Link } from 'gatsby'
import { Box, Button } from 'grommet'
import styled from 'styled-components'
import { modernMail as theme } from '../lib/grommetThemes'
import { findLastIndex, includes } from 'lodash/fp'

import { ChevronRight } from './Icons'

const pageArray = ['/', '/design', '/envelope', '/list', '/confirm']

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-items: space-between;
  text-decoration: none;
  color: ${theme.color};
  padding: 0 10px 0 0;
  &[rel='active'] {
    border-bottom: 1px solid #000;
  }
  &[rel='current'] {
    border-bottom: 1px solid #000;
    padding: 0 0 0 0;
    margin-right: 10px;
  }
  &[rel='future'] {
    pointer-events: none;
    cursor: default;
  }
  button {
    padding-left: ${p => (p.rel === 'first-navLink' ? '0' : '10px')};
  }

  &.navLink-small {
    padding: 0 6px 0 0;
    button {
      padding-left: ${p => (p.rel === 'first-navLink' ? '0' : '6px')};
    }
  }
`

const StyledButton = styled(Button)`
  text-transform: uppercase !important;
`

const BreadcrumbLink = ({ children, first, to = '/', size }) => (
  <StyledLink
    rel={first ? 'first-navLink' : 'navLink'}
    className={`navLink-${size}`}
    to={to}
    getProps={({ isPartiallyCurrent, isCurrent, location, href }) => {
      const { pathname } = location
      const currentPageIndex = findLastIndex(
        x => includes(x, pathname),
        pageArray
      )
      const thisPageIndex = findLastIndex(x => includes(x, href), pageArray)

      return {
        rel:
          thisPageIndex <= currentPageIndex
            ? thisPageIndex === currentPageIndex
              ? 'current'
              : 'active'
            : 'future',
      }
    }}
  >
    {!first && (
      <ChevronRight color="dark-1" size={size === 'small' ? '8px' : 'small'} />
    )}
    <StyledButton size="small">{children}</StyledButton>
  </StyledLink>
)

export default BreadcrumbLink
