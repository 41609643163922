import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik, Field, Form } from 'formik'
import { Box, Text, ResponsiveContext } from 'grommet'
import { navigate, Link } from 'gatsby'
import { isEmpty, isEqual } from 'lodash/fp'
import styled from 'styled-components'

import Button from './Button'
import ColumnStack from './ColumnStack'
import ContentContainer from './ContentContainer'
import ResponsiveHidden from './ResponsiveHidden'
import FormField from './FormField'
import FieldSet from './FieldSet'
import FormInputS3File from './FormInputS3File'
import FormInputCheckBox from './FormInputCheckBox'
import ResponsiveStyler from './ResponsiveStyler'
import orderSetProductArt from '../store/actions/orderSetProductArt'
import { ChevronUp, ChevronDown } from './Icons'

const StyledForm = styled(Form)`
  height: 100%;
`

const postcardVisStyle = `left: 70px; top: -50px; position: relative; z-index: 0; max-width: 80%`

const letterVisStyle2 = `margin-left: -15px; margin-top: 60px; flex-grow: 1`

const NoArtText = () => (
  <Box pad={{ vertical: 'small' }}>
    <Text size="small">
      No worries! We’ll give you a call after you complete your order and we can
      design it together - for free.
    </Text>
  </Box>
)

class FormArtFiles extends React.PureComponent {
  componentDidMount() {
    const { productSku, product } = this.props
    if (isEmpty(productSku)) {
      return navigate(`/${product.toLowerCase()}`)
    }
  }
  render() {
    const { data, product, initialValues, orderSetProductArt } = this.props
    const isLetter = isEqual(product, 'letters')

    const ArtSchema = Yup.object().shape({
      artFront: Yup.string().when('noArt', {
        is: true,
        then: Yup.string().nullable(),
        otherwise: Yup.string().required('Required'),
      }),
      artBack: Yup.string().when('noArt', {
        is: true,
        then: Yup.string().nullable(),
        otherwise: isLetter ? Yup.string() : Yup.string().required('Required'),
      }),
      noArt: Yup.boolean(),
    })

    return (
      <Formik
        initialValues={initialValues}
        isInitialValid={() => ArtSchema.isValidSync(initialValues)}
        validationSchema={ArtSchema}
        onSubmit={async values => {
          const { artFront, artBack, noArt } = values
          await orderSetProductArt({
            front: artFront,
            back: artBack,
            none: noArt,
          })
          navigate(
            `/${product.toLowerCase()}/${isLetter ? 'envelope' : 'list'}`
          )
        }}
        render={({ values, isSubmitting, isValid, errors }) => (
          <StyledForm>
            <ColumnStack fill gap="medium">
              <Box flex>
                <Box flex="grow" justify="center">
                  <ResponsiveHidden
                    hideAt={['medium', 'middle', 'large', 'xlarge']}
                  >
                    <Box>
                      <Link to={`/${product}`}>
                        <Button
                          label="Previous"
                          icon={<ChevronUp size="small" color="dark-1" />}
                        />
                      </Link>
                    </Box>
                  </ResponsiveHidden>
                  <ContentContainer side="left">
                    <Box direction={isLetter ? 'row-responsive' : 'column'}>
                      <ResponsiveStyler
                        positionAt={['medium', 'large', 'xlarge']}
                        styleBlock={`z-index: 2; max-width: 80%; flex-grow: 1;`}
                      >
                        <Field
                          component={FormInputS3File}
                          product={product}
                          label={isLetter ? 'Letter Front' : 'Design Front'}
                          name="artFront"
                          filename={values['artFront-filename']}
                          accept={[
                            'image/*',
                            'application/pdf',
                            'application/x-pdf',
                          ]}
                        />
                      </ResponsiveStyler>
                      <ResponsiveStyler
                        positionAt={['medium', 'large', 'xlarge']}
                        styleBlock={
                          isLetter ? letterVisStyle2 : postcardVisStyle
                        }
                      >
                        <ResponsiveContext.Consumer>
                          {size => (
                            <Field
                              component={FormInputS3File}
                              label={isLetter ? 'Letter Back' : 'Design Back'}
                              name="artBack"
                              product={product}
                              filename={values['artBack-filename']}
                              padTop={size !== 'small'}
                              accept={[
                                'image/*',
                                'application/pdf',
                                'application/x-pdf',
                              ]}
                            />
                          )}
                        </ResponsiveContext.Consumer>
                      </ResponsiveStyler>
                    </Box>
                  </ContentContainer>
                </Box>
                <ResponsiveHidden>
                  <Box>
                    <Link to={`/${product}`}>
                      <Button
                        label="Previous"
                        icon={<ChevronUp size="small" color="dark-1" />}
                      />
                    </Link>
                  </Box>
                </ResponsiveHidden>
              </Box>
              <Box flex>
                <ContentContainer justify="center">
                  <FieldSet>
                    <Text color="dark-2" size="small">
                      Upload Design
                    </Text>
                    {!(values.artFront || values.artBack) && (
                      <FormField
                        label="I don't have a design"
                        extra={values.noArt && <NoArtText />}
                      >
                        <Field component={FormInputCheckBox} name="noArt" />
                      </FormField>
                    )}
                  </FieldSet>
                </ContentContainer>
                <Box margin={{ top: 'medium' }}>
                  <Button
                    primary
                    type="submit"
                    alignSelf="end"
                    disabled={!isValid}
                    reverse
                    label="Next"
                    icon={<ChevronDown size="small" />}
                  />
                </Box>
              </Box>
            </ColumnStack>
          </StyledForm>
        )}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    artFront: state.order.art.artFront,
    artBack: state.order.art.artBack,
    noArt: state.order.art.none,
  },
  productSku: state.order.productSku,
})

export default connect(
  mapStateToProps,
  { orderSetProductArt }
)(FormArtFiles)
