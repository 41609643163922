import React from 'react'
import logo from '../images/mm-logo.svg'
import styled from 'styled-components'

const Sized = styled.img`
  display: block;
  max-width: ${p => p.size};
`

const Logo = ({ size }) => <Sized size={size} src={logo} />

export default Logo
