import './layout.css'
import React from 'react'
import PropTypes from 'prop-types'
import { Box, Grommet, ResponsiveContext } from 'grommet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './Footer'
import { modernMail as theme } from '../lib/grommetThemes'
import { includes } from 'lodash/fp'

const stackSizes = ['xsmall', 'small']

const Layout = ({ children, productNav, normal }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      return (
        <Grommet
          theme={theme}
          style={{ height: normal ? 'auto' : '100%', minHeight: '600px' }}
        >
          <ResponsiveContext.Consumer>
            {size => (
              <Box fill={includes(size, stackSizes) ? false : true}>
                <Box
                  direction="column"
                  flex
                  pad="medium"
                  fill={includes(size, stackSizes) ? false : true}
                >
                  <Box fill flex="grow" className="content-container">
                    <Header
                      siteTitle={data.site.siteMetadata.title}
                      productNav={productNav}
                    />
                    {children}
                  </Box>
                  <Box>
                    <Footer />
                  </Box>
                </Box>
              </Box>
            )}
          </ResponsiveContext.Consumer>
        </Grommet>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  productNav: PropTypes.node,
}

export default Layout
