import { Link } from 'gatsby'
import { Box, Layer, Heading, Button, Text } from 'grommet'
import PropTypes from 'prop-types'
import React from 'react'
import { Close } from 'grommet-icons'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.6em;
`

const MainMenu = ({ siteTitle, isOpen, onClose }) =>
  isOpen && (
    <Layer
      position="right"
      onEsc={() => onClose(false)}
      onClickOutside={() => onClose(false)}
      full="vertical"
    >
      <Box
        pad="small"
        width="small"
        align="start"
        background="dark-1"
        fill="vertical"
      >
        <Box direction="row" align="center" gap="small">
          <Box flex align="start" pad="small">
            <Heading margin="none" level={5}>
              {siteTitle}
            </Heading>
          </Box>
          <Box>
            <Button onClick={() => onClose(false)}>
              <Close />
            </Button>
          </Box>
        </Box>
        <Box width="small" pad="small" direction="column">
          <StyledLink to="/">
            <Text size="small">Home</Text>
          </StyledLink>
          <StyledLink to="/postcards">
            <Text size="small">Postcards</Text>
          </StyledLink>
          <StyledLink to="/letters">
            <Text size="small">Letters</Text>
          </StyledLink>
          <StyledLink to="/contact">
            <Text size="small">Contact</Text>
          </StyledLink>
        </Box>
      </Box>
    </Layer>
  )

MainMenu.propTypes = {
  siteTitle: PropTypes.string,
}

MainMenu.defaultProps = {
  siteTitle: ``,
}

export default MainMenu
