const scrubFilename = filename => filename.replace(/[^\w\d_\-\.]+/gi, '')

export default function getSignedUrl(file) {
  const fileName = scrubFilename(file.name)
  return fetch(
    `${process.env.GATSBY_UPLOADS_URL}${
      process.env.GATSBY_REQUEST_UPLOAD_PATH
    }`,
    {
      method: 'POST',
      body: JSON.stringify({
        name: fileName,
        type: file.type,
      }),
    }
  )
    .then(response => response.json())
    .then(data => ({
      signedUrl: data.uploadURL,
      bucketName: data.bucketName,
      s3Key: data.s3Key,
      keyHash: data.keyHash,
      fileName,
    }))
}
