import React from 'react'
import { Box, Text, ResponsiveContext } from 'grommet'
import { Link } from 'gatsby'
import { isEqual } from 'lodash/fp'

import BreadcrumbLink from './BreadcrumbLink'

const ProductNav = ({ product }) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box direction="row" justify="center" pad={{ vertical: 'small' }}>
        <BreadcrumbLink size={size} to={`/${product}`} first={true}>
          <Text size={size === 'small' ? 'xsmall' : 'medium'}>Product</Text>
        </BreadcrumbLink>
        <BreadcrumbLink size={size} to={`/${product}/design`}>
          <Text size={size === 'small' ? 'xsmall' : 'medium'}>
            {isEqual(product, 'letters') ? 'Letter' : 'Design'}
          </Text>
        </BreadcrumbLink>
        {isEqual(product, 'letters') && (
          <BreadcrumbLink size={size} to={`/${product}/envelope`}>
            <Text size={size === 'small' ? 'xsmall' : 'medium'}>Envelope</Text>
          </BreadcrumbLink>
        )}
        <BreadcrumbLink size={size} to={`/${product}/list`}>
          <Text size={size === 'small' ? 'xsmall' : 'medium'}>List</Text>
        </BreadcrumbLink>
        <BreadcrumbLink size={size} to={`/${product}/confirm`}>
          <Text size={size === 'small' ? 'xsmall' : 'medium'}>Confirm</Text>
        </BreadcrumbLink>
      </Box>
    )}
  </ResponsiveContext.Consumer>
)

export default ProductNav
