/**
 * Taken, CommonJS-ified, and heavily modified from:
 * https://github.com/flyingsparx/NodeDirectUploader
 */

const successResponses = [200, 201]

// const getS3Url = (fileName, bucketName) =>
//   `https://${bucketName}.s3.amazonaws.com/${fileName.replace(' ', '+')}`
const getS3Url = (fileName, bucketName) =>
  `${process.env.GATSBY_ASSETS_URL}?key=${fileName.replace(' ', '+')}`

const defaultHandlers = {
  onFinishS3Put: function(signResult, file, url, fileName) {
    return console.log('base.onFinishS3Put()', signResult.publicUrl)
  },
  preprocess: function(file, next) {
    return next(file)
  },
  onProgress: (percent, status, file) =>
    console.log('base.onProgress()', percent, status),
  onError: (status, file) => console.log('base.onError()', status),
}

const createCORSRequest = function(method, url, argOpts) {
  var opts = argOpts || {}
  var xhr = new XMLHttpRequest()

  if (xhr.withCredentials != null) {
    xhr.open(method, url, true)
    if (opts.withCredentials != null) {
      xhr.withCredentials = opts.withCredentials
    }
  } else if (typeof window.XDomainRequest !== 'undefined') {
    xhr = new window.XDomainRequest()
    xhr.open(method, url)
  } else {
    xhr = null
  }
  return xhr
}

const uploadToS3 = function(
  file,
  signedUrl,
  eventHandlers = defaultHandlers,
  fileName,
  bucketName,
  keyHash
) {
  var xhr = createCORSRequest('PUT', signedUrl)
  const { onProgress, onFinishS3Put, onError } = eventHandlers
  if (!xhr) {
    this.onError('CORS not supported', file)
  } else {
    xhr.onload = function() {
      if (successResponses.indexOf(xhr.status) >= 0) {
        onProgress(100, 'Upload completed', file)
        return onFinishS3Put(
          signedUrl,
          file,
          getS3Url(keyHash, bucketName),
          fileName
        )
      } else {
        return onError('Upload error: ' + xhr.status, file)
      }
    }.bind(this)
    xhr.onerror = function() {
      return onError('XHR error', file)
    }.bind(this)
    xhr.upload.onprogress = function(e) {
      var percentLoaded
      if (e.lengthComputable) {
        percentLoaded = Math.round((e.loaded / e.total) * 100)
        return onProgress(
          percentLoaded,
          percentLoaded === 100 ? 'Finalizing' : 'Uploading',
          file
        )
      }
    }.bind(this)
  }
  xhr.setRequestHeader('Content-Type', file.type)
  return xhr.send(file)
}

const abortUpload = function(httprequest) {
  httprequest && httprequest.abort()
}

export default uploadToS3
