import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'grommet'
import { modernMail as theme } from '../lib/grommetThemes'

const Label = styled.label`
  font-weight: bold;
  align-content: end;
  display: flex;
  text-align: right;
`

const FormField = ({
  children,
  label,
  htmlFor,
  fill = 'input',
  size = 'medium',
  error,
  align,
  extra,
}) => (
  <Box>
    <Box direction="row" align="center" pad={{ top: 'xsmall' }}>
      <Box
        flex={fill === 'label' ? 'grow' : 'shrink'}
        align={align}
        pad={align === 'end' && { right: 'small' }}
      >
        <Label htmlFor={htmlFor}>
          <Text color={error ? 'status-error' : theme.color} size={size}>
            {label}
          </Text>
        </Label>
      </Box>
      <Box flex={fill === 'input' ? 'grow' : 'shrink'} align="end">
        {children}
      </Box>
    </Box>
    {extra && extra}
  </Box>
)

export default FormField
