import React from 'react'
import { Box, Text, CheckBox } from 'grommet'
import { get, isEqual } from 'lodash/fp'
import styled from 'styled-components'

const FormInputCheckBox = ({ field, form, label }) => {
  const { setFieldValue } = form
  const { value, onChange, name } = field
  return (
    <CheckBox
      label={label}
      checked={value}
      onChange={({ target: { checked } }) => {
        setFieldValue(name, checked)
      }}
    />
  )
}

export default FormInputCheckBox
