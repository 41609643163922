import React from 'react'
import SmartLink from './SmartLink'
import { Text } from 'grommet'
import styled from 'styled-components'

const FooterLink = ({ children, to }) => {
  const StyledLink = styled(SmartLink)`
    text-decoration: none;
    text-transform: uppercase;
  `
  return (
    <StyledLink to={to}>
      <Text color="dark-3" size="xsmall">
        {children}
      </Text>
    </StyledLink>
  )
}

export default FooterLink
