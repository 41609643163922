import { Box } from 'grommet'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import mediaTemplates from '../lib/mediaTemplates'

const getMaxWidth = size => {
  switch (size) {
    case 'small':
      return 'none'
    case 'medium':
      return '400px'
    case 'large':
      return '520px'
    default:
      return '400px'
  }
}

const Container = styled(Box)`
  position: relative;
  max-width: ${getMaxWidth('large')};

  ${mediaTemplates.medium`
    max-width: ${getMaxWidth('medium')};
  `}
  ${mediaTemplates.small`
    max-width: ${getMaxWidth('small')};
  `}
`

const ContentContainer = ({ children, side, justify }) => (
  <Container
    fill
    justify={justify ? justify : 'start'}
    alignSelf={side === 'left' ? 'end' : 'start'}
  >
    {children}
  </Container>
)

ContentContainer.propTypes = {
  children: PropTypes.node,
  side: PropTypes.string,
  justify: PropTypes.string,
}

export default ContentContainer
