import React from 'react'
import { Text, Box } from 'grommet'
import Button from './Button'

const UploadedS3File = ({ filename, label, url, onPreview }) => (
  <Box
    direction="row"
    alignSelf="center"
    flex="grow"
    pad={{ horizontal: 'small' }}
    align="start"
  >
    <Box flex="grow" align="start">
      <Box direction="row" align="center" gap="small">
        <Text size="small" color="dark-3">
          {filename}
        </Text>
        {onPreview && (
          <Button size="small" plain hoverIndicator onClick={onPreview}>
            Preview
          </Button>
        )}
      </Box>
    </Box>
  </Box>
)

export default UploadedS3File
