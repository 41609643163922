import React from 'react'
import { Box, ResponsiveContext } from 'grommet'
import { includes } from 'lodash/fp'
import styled from 'styled-components'

const Styled = styled(Box)`
  ${p => p.s}
`

const ResponsiveStyler = ({
  children,
  positionAt = 'small',
  styleBlock,
  ...rest
}) => {
  const sizes = Array.isArray(positionAt) ? positionAt : [positionAt]
  return (
    <ResponsiveContext.Consumer>
      {size =>
        includes(size, sizes) ? (
          <Styled s={styleBlock} {...rest}>
            {children}
          </Styled>
        ) : (
          <>{children}</>
        )
      }
    </ResponsiveContext.Consumer>
  )
}

export default ResponsiveStyler
