import React from 'react'
import { Button as GrommetButton, Box } from 'grommet'
import styled from 'styled-components'

const Button = styled(GrommetButton)`
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: ${p => (p.size === 'small' ? '70%' : '100%')};
  line-height: ${p => (p.size === 'small' ? '70%' : '100%')};
`

export default Button
