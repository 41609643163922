import React from 'react'
import { Box } from 'grommet'
import { Facebook, Instagram, Linkedin } from 'grommet-icons'

import FooterLink from './FooterLink'

const Footer = () => (
  <Box direction="row-responsive" justify="between">
    <Box direction="row-responsive" gap="small">
      <FooterLink to="/contact">Contact</FooterLink>
      <FooterLink to="/terms-conditions">Terms &amp; Conditions</FooterLink>
      <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
    </Box>
    <Box direction="row" gap="small">
      <FooterLink to="https://www.facebook.com/ModernMailco/">
        <Facebook size="small" />
      </FooterLink>
      <FooterLink to="https://www.instagram.com/modernmail/">
        <Instagram size="small" />
      </FooterLink>
      <FooterLink to="https://www.linkedin.com/company/modern-mail-co">
        <Linkedin size="small" />
      </FooterLink>
    </Box>
  </Box>
)

export default Footer
