import { Link } from 'gatsby'
import { Box, ResponsiveContext } from 'grommet'
import PropTypes from 'prop-types'
import React from 'react'

const getMaxWidth = (size, inner) => {
  switch (size) {
    case 'small':
      return inner ? '100%' : '300px'
      break
    case 'medium':
      return inner ? '600px' : '900px'
      break
    case 'large':
      return inner ? '700px' : '1000px'
      break
    case 'xlarge':
      return '1200px'
      break
    default:
      return '900px'
  }
}

const ConstrainedContainer = ({ children, inner }) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box
        style={{ maxWidth: `${getMaxWidth(size, inner)}` }}
        margin="auto"
        fill
        size={inner ? undefined : size}
      >
        {children}
      </Box>
    )}
  </ResponsiveContext.Consumer>
)

export default ConstrainedContainer
