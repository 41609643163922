const letterSpacing = `0.06em`

export const modernMail = {
  color: '#000',
  global: {
    colors: {
      brand: '#000000',
      black: '#000000',
      'accent-1': '#46a3da',
      'dark-1': '#000000',
      'light-3': '#e6e6e6',
      text: {
        light: '#000',
        dark: '#fff',
      },
      border: {
        dark: '#000000',
        light: '#000000',
      },
      focus: 'accent-1',
    },
    input: { border: { radius: '0px' }, text: { color: '#000000' } },
    font: {
      family: "'Montserrat', Arial, sans-serif",
      size: '16px',
    },
  },
  text: {
    extend: `letter-spacing: ${letterSpacing};`,
  },
  button: {
    extend: `letter-spacing: ${letterSpacing};`,
    border: { radius: '0px', color: '#000000', width: '1px' },
    text: {
      color: '#000000',
    },
    padding: {
      vertical: '6px',
      horizontal: '24px',
    },
  },
  box: {
    colors: { border: '#000000' },
  },
  checkBox: { border: { radius: '0px' } },
  layer: { border: { radius: '0px' } },
}
